<template>
  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <div class="col-xl-12">
      <div class="card mb-xl-8">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Heading-->
          <div class="text-center">
            <!--begin::Title-->
            <div class="card-label fw-bolder fs-3 mb-3">{{ $t("ogza.createModal.title") }}</div>
            <!--end::Title-->
          </div>
          <!--end::Heading-->
          <!--begin::Form-->
          <Form
            id="kt_account_profile_details_form"
            class="form"
            :validation-schema="form"
          >
            <!--begin::Card body-->
            <div class="card-body p-9 ps-6 pb-1">
              <div class="row mb-2">
                <div class="col-md-6">
                  <div class="row mb-5">
                <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t("ogza.createModal.modalIcon") }}</label>

                <div class="col-lg-8">
                  <div
                    class="image-input image-input-outline"
                    data-kt-image-input="true"
                    style="background-image: url(media/avatars/blank.png)"
                  >
                    <div
                      class="image-input-wrapper w-125px h-125px"
                      :style="`background-image: url(media/avatars/blank.png)`"
                    ></div>
                    <label
                      class="
                        btn btn-icon btn-circle btn-active-color-primary
                        w-25px
                        h-25px
                        bg-white
                        shadow
                      "
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                    >
                      <i class="bi bi-pencil-fill fs-7"></i>
                      <input
                        type="file"
                        name="avatar"
                        accept=".png, .jpg, .jpeg"
                      />

                      <Field type="hidden" name="avatar_remove" />
                    </label>

                    <span
                      class="
                        btn btn-icon btn-circle btn-active-color-primary
                        w-25px
                        h-25px
                        bg-white
                        shadow
                      "
                      data-kt-image-input-action="remove"
                      data-bs-toggle="tooltip"
                      title="Remove avatar"
                    >
                      <i class="bi bi-x fs-2"></i>
                    </span>
                  </div>

                  <div class="form-text">
                    {{ $t("ogza.createModal.modalIconDesc") }}
                  </div>
                </div>
              </div>
                </div>
              </div>
              <div class="row mb-0">
                <div class="col-md-4">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark required"
                      > {{ $t("ogza.createModal.modalName") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="moduleName"
                      autocomplete="off"
                      :placeholder="$t('ogza.createModal.enterModalName')"  
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="moduleName" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="col-md-4">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("ogza.createModal.modalDesc") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="moduleInfo"
                      autocomplete="off"
                      :placeholder="$t('ogza.createModal.enterModalDesc')"                      />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="moduleInfo" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="col-md-4">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("ogza.createModal.price") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="number"
                      name="price"
                      autocomplete="off"
                      :placeholder="$t('ogza.createModal.enterPrice')"      
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="price" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
              </div>
              <div class="row mb-0">
                <div class="col-md-12">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("ogza.createModal.detailedDesc") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="description"
                      autocomplete="off"
                      :placeholder="$t('ogza.createModal.enterDetailedDesc')" 
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="description" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
              </div>
              <div class="row mb-0">
                <div class="col-md-6">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("ogza.createModal.question1") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="questionOne"
                      autocomplete="off"
                      :placeholder="$t('ogza.createModal.enterQuestion')" 
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="questionOne" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="col-md-6">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("ogza.createModal.answer") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="answerOne"
                      autocomplete="off"
                      :placeholder="$t('ogza.createModal.enterAnswer')" 
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="answerOne" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
              </div>
              <div class="row mb-0">
                <div class="col-md-6">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("ogza.createModal.question2") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="questionTwo"
                      autocomplete="off"
                      :placeholder="$t('ogza.createModal.enterQuestion')" 
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="questionTwo" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="col-md-6">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("ogza.createModal.answer") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="answerTwo"
                      autocomplete="off"
                      :placeholder="$t('ogza.createModal.enterAnswer')" 
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="answerTwo" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
              </div>

              <div class="row mb-0">
                <div class="col-md-12">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("ogza.createModal.adminPropmt") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="prompt"
                      autocomplete="off"
                      :placeholder="$t('ogza.createModal.enterPrompt')" 
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="prompt" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
              </div>
              <div class="row mb-0">
                <div class="col-md-12">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("ogza.createModal.userSessionLimit") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="number"
                      name="sessionLimit"
                      autocomplete="off"
                      :placeholder="$t('ogza.createModal.enterLimit')" 
                      min="1"
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="sessionLimit" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
              </div>
              <div class="row mb-0">
                <div class="col-md-12">
                  <div class="fv-row mb-10">
                    <label class="form-label fs-6 fw-bolder text-dark"
                      >{{ $t("ogza.createModal.details") }}</label
                    >
                    <div class="form-check">
                      <Field
                        type="checkbox"
                        class="form-check-input"
                        name="sessionLimitCheck"
                        value="Kullanıcı Session Sınırı"
                      />
                      <label class="form-check-label"
                        >{{ $t("ogza.createModal.userSessionLimit") }}</label
                      >
                    </div>
                    <div class="form-check">
                      <Field
                        type="checkbox"
                        class="form-check-input"
                        name="voiceRecorder"
                        value="Sesli konuşma"
                      />
                      <label class="form-check-label">{{ $t("ogza.createModal.speakOutLoud") }}</label>
                    </div>
                    <div class="form-check">
                      <Field
                        type="checkbox"
                        class="form-check-input"
                        name="VideoRecorder"
                        value="Görüntülü görüşme"
                      />
                      <label class="form-check-label">{{ $t("ogza.createModal.videoCall") }}</label>
                    </div>
                    <div class="form-check">
                      <Field
                        type="checkbox"
                        class="form-check-input"
                        name="Guest"
                        value="Dışarıdan misafir kullanıcılar erişebilir mi?"
                      />
                      <label class="form-check-label"
                        >{{ $t("ogza.createModal.guestUser") }}</label
                      >
                    </div>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="experience" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <!--begin::Submit button-->
                <button
                  type="submit"
                  id="kt_account_profile_details_submit"
                  ref="submitButton"
                  class="btn btn-primary"
                >
                  <span class="indicator-label">{{ $t("common.button.create") }}</span>
                  <span class="indicator-progress">
                    {{ $t("common.pleaseWait") }}
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
                <!--end::Submit button-->
              </div>
            </div>
            <!--end::Card body-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
  <!--end::Row-->
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "create-modal",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();

    //Create form validation object
    const form = Yup.object().shape({
      name: Yup.string().required(t("validators_error.required")).label("Name"),
      surname: Yup.string()
        .required(t("validators_error.required"))
        .label("Surname"),
      email: Yup.string()
        .required(t("validators_error.required"))
        .email(t("validators_error.email"))
        .label("Email"),
      date: Yup.date()
        .required(t("validators_error.required"))
        .max(
          new Date(2006, 11, 31),
          t("validators_error.min18age", { date: "31/12/2006" })
        )
        .label("Date"),
      department: Yup.string()
        .required(t("validators_error.required"))
        .label("Department"),
      jobPosition: Yup.string()
        .required(t("validators_error.required"))
        .label("JobPosition"),
    });

    onMounted(() => {
      setCurrentPageTitle(t("ogza.createModal.title"));
    });

    return {
      form,
    };
  },
});
</script>
